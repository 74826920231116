import axios from 'axios';

export const formService={
    contactForm, 
    festivalForm
}
function contactForm(userData) {
    let url = `${process.env.REACT_APP_API_URL}/api/program_applicants`;
    const requestOptionsPost = {
      method: 'POST',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: {
        program_applicant: {
          first_name: userData.name,
          last_name: userData.surname,
          email: userData.email,
          city:userData.city,
          learning_institute:userData.institution,
          gdpr_agreement:userData.gdprAgreement,
          newsletter_subscription:userData.newsletterSubscription,
          domains:userData.interestDomain,
          other_domains:userData.otherDomain
        }
      }
    };
  
    return axios(requestOptionsPost);
  }
  function festivalForm(userData) {
    let url = `${process.env.REACT_APP_API_URL}/api/summerwell_applicants`;
    const requestOptionsPost = {
      method: 'POST',
      url,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      data: {
        summerwell_applicant: {
          first_name: userData.firstName,
          last_name: userData.lastName,
          email: userData.email,
          grade:userData.grade,
          challenges:userData.challenges,
          platform_preferences:userData.platformPreferences,
          information:userData.information,
          faculty_options:userData.facultyOptions,
          gdpr_agreement:userData.gdprAgreement,
          newsletter_subscription:userData.newsletterSubscription,
          other_challenges:userData.otherChallenges,
          other_platform_preferences:userData.otherPlatformPreferences,
          other_faculty_options:userData.otherFacultyOptions
        }
      }
    };
  
    return axios(requestOptionsPost);
  }