import React, { useState, useEffect } from 'react';
import './Homepage.scss';
import '../../resources/main.scss';
import MobileMenu from "../../components/Menu/MobileMenu";
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom'
import FAQSection from "./FaqSection";
import faqs from './faqs.json'
import Slider from "react-slick";
import { NextArrow, PrevArrow } from './CustomArrow';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import mentors from './mentors.json'
import { formService } from "../../services/FormService";
const Homepage = () => {
   const { search } = useLocation();
   const [isAltDomeniuChecked, setIsAltDomeniuChecked] = useState(false);

   var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeable: true,
      centerMode: true,
      dots: false,
      adaptiveHeight: true,
      centerMode: true,
      centerPadding: '0',
      swipe: true,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
         {
             breakpoint: 768, 
             settings: {
                 slidesToShow: 1,
                 centerMode: false, 
             }
         }
     ]
   };
   var partnerSettings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeable: true,
      centerMode: true,
      dots: false,
      adaptiveHeight: true,
      centerMode: true,
      centerPadding: '0',
      swipe: true,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />
   };
   const [formData, setFormData] = useState({
      name: '',
      surname: '',
      email: '',
      institution: '',
      city: '',
      interestDomain: [],
      gdprAgreement: false,
      newsletterSubscription: false,
      otherDomain: ''
   });
   const [mentorsData, setMentors] = useState(mentors)

   const handleChange = (e) => {
      const { name, value, type, checked } = e.target;

      if (type === 'checkbox' && name === 'interestDomain') {
         const newOptions = checked
            ? [...formData.interestDomain, value]
            : formData.interestDomain.filter(option => option !== value);

         setFormData((prevData) => ({
            ...prevData,
            interestDomain: newOptions,
         }));
      } else {
         setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
         }));
      }
   };
   const handleSubmit = (e) => {
      e.preventDefault();
      formService.contactForm(formData).then(response => {
         toast.success(`Cererea ta a fost trimisa cu succes.`);
      })
         .catch(error => {
            console.log(error);
            toast.error('A apărut o problemă, vă rugăm să încercați din nou.');
         });
   };
   useEffect(() => {
      const params = new URLSearchParams(search);
      const scrollToId = params.get('scrollTo');
      if (scrollToId) {
         const element = document.getElementById(scrollToId);
         if (element) {
            window.scrollTo({
               top: element.offsetTop,
               behavior: 'smooth',
            });
         }
      }
   }, [search]);
   return (
      <div className='standard hp form'>
         <div className='hp__header'>
            <img src='/images/logo.png' alt='logo' className='hp__logo' />
            <MobileMenu />
            <div className='hp__desktop-menu'>
               <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                  <h4>Despre </h4>
               </ScrollLink>
               <ScrollLink to='ce-inseamna' className='standard__link' smooth={true} duration={500}>
                  <h4>Ce înseamnă</h4>
               </ScrollLink>
               <ScrollLink to='skillup' className='standard__link' smooth={true} duration={500}>
                  <h4>SkillUp</h4>
               </ScrollLink>
               <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                  <h4>Mentori</h4>
               </ScrollLink>
               <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                  <h4>Impact social</h4>
               </ScrollLink>
               <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                  <h4>Parteneri</h4>
               </ScrollLink>
               <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                  <h4>FAQ</h4>
               </ScrollLink>
            </div>
         </div>

         <div className='hp__banner'>
            <div className='hp__banner-info'>
               <h2 className='hp__title'>Educație aplicată pentru tineri</h2>
               <h3 className='hp__subtitle'>Construim punți către viitor prin oferirea de experiențe practice și programe educaționale aplicate pentru tineri</h3>
               <ScrollLink to='chestionar' className='standard__link' smooth={true} duration={500}>
                  <button className='hp__banner-button'>Aplică acum</button>
               </ScrollLink>
            </div>


            <div className='hp__benefits benefits'>
               <div className='hp__benefit'>
                  <img src='/images/benefit-icon.png' alt='hp__benefit' />
                  <h3>Abilități practice</h3>
                  <h4>Ghidați de specialiști pe diferite domenii de activitate</h4>
               </div>
               <div className='hp__benefit'>
                  <img src='/images/benefit-icon.png' alt='hp__benefit' />
                  <h3>Întâlniri cu antreprenori</h3>
                  <h4>CEO și top management ale companiilor</h4>
               </div>
               <div className='hp__benefit'>
                  <img src='/images/benefit-icon.png' alt='hp__benefit' />
                  <h3>Resurse educaționale</h3>
                  <h4>Acces gratuit la resursele educaționale timp de 1 an</h4>
               </div>
            </div>
         </div>

         <div className='hp__about' id='despre'>
            <h2 className='hp__section-title'>Despre Edu<span>UP</span></h2>
            <p className='hp__section-description'>Asociația EduUP oferă o nouă perspectivă, un context extracuricular ce are ca scop dezvoltarea abilităților și competențelor tinerilor pentru a putea face față schimbărilor pieței și a naviga către succes. Creăm un mediu educațional unde tinerii sunt încurajați să-și exploreze potențialul, să inoveze și să colaboreze.</p>
         </div>

         <div className='hp__meaning' id='ce-inseamna'>
            <h2 className='hp__section-title hp__meaning-title'>Ce înseamnă Edu<span>UP</span></h2>
            <div className='hp__meaning-wrapper'>
               <div className='hp__meaning-box'>
                  <h4>Misiune</h4>
                  <p>Punem accent pe îmbunătățirea aptitudinilor practice și a lucrului în echipă, cultivăm gândirea critică, creativitatea și adaptabilitatea, abilități cheie în lumea dinamică, în continuă schimbare.</p>
                  <img src='/images/meaning1.png' />
               </div>
               <div className='hp__meaning-box'>
                  <h4>Viziune</h4>
                  <p>Creăm o comunitate de tineri instruiți de practicieni care îi inspiră și le oferă experiențe aplicate.</p>
                  <img src='/images/meaning2.png' />
               </div>
               <div className='hp__meaning-box'>
                  <h4>Valori</h4>
                  <p>Inovația, incluziunea, colaborarea: promovăm abordări noi, respectăm diversitatea și creăm echipe cu interese comune pentru atingerea potențialului maxim.</p>
                  <img src='/images/meaning3.png' />
               </div>
            </div>

         </div>

         <div className='hp__meaning'>
            <div className='hp__meaning-box hp__info-box'>
               <h2>Skill<span>UP</span></h2>
               <Link to='/' className='standard__link'><p>Înscrieri deschise</p></Link>
            </div>
            <div className='hp__meaning-box hp__info-box'>
               <h2>Edu<span className='yellow-span'>HUB</span></h2>
               <p>DISPONIBIL CURÂND</p>
            </div>
            <div className='hp__meaning-box hp__info-box'>
               <h2>Masterclass</h2>
               <p>DISPONIBIL CURÂND</p>
            </div>
         </div>

         <div className='hp__program-section'>
            <h2 className='hp__section-title'>Despre programul Edu<span>UP</span></h2>
            <p className='hp__section-description hp__about-description'>În documentarea acestui program, am creat un grup de lucru format din reprezentanți ai elevilor din Municipiul București alături de alți elevi implicați civic și am dezbătut aspectele cu care se confruntă ei în ceea ce privește domeniul educațional.</p>
            <h3 className='hp__program-title'>Provocările tinerei generații</h3>
            <div className='hp__program-wrapper'>
               <div className='hp__program-box'>
                  <h2>01</h2>
                  <h3>Lipsa educației practice</h3>
                  <p>Adolescenții asimilează informații și conținut teoretic, iar de cele mai multe ori acestea nu sunt însoțite de activități practice.</p>
               </div>
               <div className='hp__program-box yellow yello-cadran'>
                  <h2>02</h2>
                  <h3>Competențe scăzute</h3>
                  <p>Procesul educațional desfășurat în instituțiile de învățământ nu se axează pe competențele solicitate de piața muncii.</p>
               </div>
               <div className='hp__program-box purple'>
                  <h2>03</h2>
                  <h3>Acces limitat la experți</h3>
                  <p>Alături de profesori, adolescenții au nevoie să se conecteze cu mentori și specialiști din companii pentru perspective practice.</p>
               </div>
            </div>
            <div className="right-aligned">
               <p className='hp__section-description hp__more-about'><b>PROGRAMUL Skill<span>UP</span></b> își propune să satisfacă nevoia liceenilor de educație practică oferită de specialiști, de dezvoltare de abilități și competențe și de conectare cu mentori.</p>
            </div>

         </div>

         <div className='hp__pros-section' id='skillup'>
            <div className='hp__skill-title'>
               <h2 className='hp__section-title '>Skill<span>UP</span>& Business</h2>
               <ScrollLink to='chestionar' className='standard__link standard__only-desktop' smooth={true} duration={500}>

                  <button className='hp__banner-button'>Aplică acum</button>
               </ScrollLink>
            </div>

            <div className='hp__skill-right'>
               <p className='hp__section-description'><b>Obiectiv: </b> Definirea abilităților și punctelor forte ale elevilor și crearea căilor lor de învățare cu ajutorul practicienilor</p>
               <p className='hp__section-description'><b>Target: </b> Adolescenții din clasele a X-a – a XII-a</p>
               <p className='hp__section-description'><b>Grup: </b>  20 liceeni </p>
               <p className='hp__section-description'><b>Durată: </b> 14 zile</p>
               <Link to='/' className='standard__link standard__only-mobile'>
                  <button className='hp__banner-button apply-button'>Aplică acum</button>
               </Link>
               <div class="hp__pros-container">
                  <div className='hp__pro hp__pro-title standard__only-mobile'>
                     <h3>Beneficii</h3>

                  </div>
                  <div class="hp__pro hp__pro-yellow">
                     <h4>Business training cu practicieni</h4>
                  </div>
                  <div class="hp__pro hp__pro-blue">
                     <h4>Întâlniri cu antrepernori și experți în business</h4>
                  </div>
                  <div class="hp__pro hp__pro-red">
                     <h4>CV structurat cu expertiza unui specialist HR</h4>
                  </div>
                  <div class="hp__pro hp__pro-blue">
                     <h4>Abilități practice, mentalitate de antreprenor</h4>
                  </div>
                  <div class="hp__pro hp__pro-red">
                     <h4>Înscriere în grup Alumni cu acces gratuit la resurse educaționale</h4>
                  </div>
                  <div className='hp__pro hp__pro-title standard__only-desktop'>
                     <h3>Beneficii</h3>

                  </div>
                  <div class="hp__pro hp__pro-yellow">
                     <h4>Participare la evenimente de business</h4>
                  </div>
                  <div class="hp__pro hp__pro-blue">
                     <h4>Acces la programe de voluntariat consistente și joburi sezoniere</h4>
                  </div>
                  <div class="hp__pro hp__pro-yellow">
                     <h4>Suport și ghidaj oferite de specialiști</h4>
                  </div>
                  <div class="hp__pro hp__pro-blue">
                     <h4>Premii puse la dispoziție de parteneri</h4>
                  </div>
                  <div class="hp__pro hp__pro-red">
                     <h4>O zi cu un CEO sau top management</h4>
                  </div>
               </div>
               <h4 className='hp__list-title'>Descriere:</h4>
               <ul class="hp__list">
                  <div>
                     <li>Proiect personal pentru a fi adăugat la CV</li>
                     <li>Evaluare personală de către echipa de traineri</li>
                     <li>Workshop-uri de abilități</li>
                     <li>Dezvoltare prin activități aplicate</li>
                     <li>Masterclass Q&A</li>
                  </div>
                  <div>
                     <li>Gen Z - Gen Y Business Talks</li>
                     <li>Descoperă liderii</li>
                     <li>Focus grup</li>
                     <li>Suport din partea echipei de proiect</li>
                  </div>

               </ul>
            </div>


         </div>

         <div className='hp__form-section' id='chestionar'>
            <img src='/images/form-img.png' className='hp__form-img' />
            <div className='hp__form-right'>
               <h3 className='hp__form-subtitle'>Dacă vrei să primești programa, completează câmpurile de mai jos:</h3>
               <form onSubmit={handleSubmit} className='hp__form'>
                  <div className='hp__form-box'>
                     <input
                        type="text"
                        name="name"
                        required
                        placeholder="Nume"
                        value={formData.name}
                        onChange={handleChange}
                     />
                     <input
                        type="text"
                        name="surname"
                        required
                        placeholder="Prenume"
                        value={formData.surname}
                        onChange={handleChange}
                     />
                  </div>
                  <div className='hp__form-box'>
                     <input
                        type="email"
                        required
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                     />
                     <input
                        type="text"
                        required
                        name="institution"
                        placeholder="Instituția de învățământ"
                        value={formData.institution}
                        onChange={handleChange}
                     />
                  </div>

                  <input
                     type="text"
                     required
                     name="city"
                     placeholder="Oras"
                     value={formData.city}
                     onChange={handleChange}
                  />
                  <label className='form__label hp__domain-label'>Domenii de interes:</label>

                  <div className='form__checkbox-container'>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="Drept"
                           checked={formData.interestDomain.includes("Drept")}
                           onChange={handleChange}
                        />
                        <h5>Drept</h5>
                     </div>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="Medicina"
                           checked={formData.interestDomain.includes("Medicina")}
                           onChange={handleChange}
                        />
                        <h5>Medicina</h5>
                     </div>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="Psihologie"
                           checked={formData.interestDomain.includes("Psihologie")}
                           onChange={handleChange}
                        />
                        <h5>Psihologie</h5>
                     </div>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="IT"
                           checked={formData.interestDomain.includes("IT")}
                           onChange={handleChange}
                        />
                        <h5>IT</h5>
                     </div>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="Business"
                           checked={formData.interestDomain.includes("Business")}
                           onChange={handleChange}
                        />
                        <h5>Business</h5>
                     </div>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="Jurnalism"
                           checked={formData.interestDomain.includes("Jurnalism")}
                           onChange={handleChange}
                        />
                        <h5>Jurnalism</h5>
                     </div>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="Marketing"
                           checked={formData.interestDomain.includes("Marketing")}
                           onChange={handleChange}
                        />
                        <h5>Marketing</h5>
                     </div>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="Design Grafic"
                           checked={formData.interestDomain.includes("Design Grafic")}
                           onChange={handleChange}
                        />
                        <h5>Design Grafic</h5>
                     </div>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="Design interior"
                           checked={formData.interestDomain.includes("Design interior")}
                           onChange={handleChange}
                        />
                        <h5>Design interior</h5>
                     </div>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="Inginerie"
                           checked={formData.interestDomain.includes("Inginerie")}
                           onChange={handleChange}
                        />
                        <h5>Inginerie</h5>
                     </div>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="Arhitectură"
                           checked={formData.interestDomain.includes("Arhitectură")}
                           onChange={handleChange}
                        />
                        <h5>Arhitectură</h5>
                     </div>
                     <div className='hp__checkbox form__checkbox'>
                        <input
                           type="checkbox"
                           name="interestDomain"
                           value="Alta"
                           checked={formData.interestDomain.includes("Alta")}
                           onChange={handleChange}
                        />
                        <h5>Alt domeniu de interes</h5>
                     </div>
                  </div>
                  {formData.interestDomain.includes("Alta") ? <input
                     type="text"
                     name="otherDomain"
                     placeholder="Domeniu de interes"
                     value={formData.otherDomain}
                     onChange={handleChange}
                  /> : ''}
                  <div className='hp__checkbox'>
                     <input
                        type="checkbox"
                        name="gdprAgreement"
                        checked={formData.gdprAgreement}
                        onChange={handleChange}
                     />
                     <h5>Sunt de acord cu normele GDPR de prelucrare a datelor personale.</h5>
                  </div>
                  <div className='hp__checkbox'>
                     <input
                        type="checkbox"
                        name="newsletterSubscription"
                        checked={formData.newsletterSubscription}
                        onChange={handleChange}
                     />
                     <h5> Sunt de acord să mă abonez la newsletterul
                        EduUP.</h5>
                  </div>
                  <button type="submit" className={`hp__form-submit ${(!formData.newsletterSubscription || !formData.gdprAgreement) ? 'disabled' : ''}`}
                     disabled={!formData.newsletterSubscription && !formData.gdprAgreement}>Vreau programa</button>
               </form>
            </div>

         </div>

         <div className='hp__mentors-section' id='mentori'>
            <h2 className='hp__section-title hp__mentors-title'>Mentorii Skill<span>UP</span>& <span className='yellow-span'>Business</span> </h2>
            <Slider  {...settings} className='hp__slider '>
               {mentorsData.map(mentor => (
                  <div className='hp__mentor' key={mentor.key}>
                     <img src={mentor.imageUrl} alt='mentor' />
                     <h4>{mentor.name}</h4>
                     <h5>{mentors.function}</h5>
                     <h5>{mentor.brand}</h5>
                     <h5><i>{mentor.tags}</i></h5>
                  </div>
               ))}
             
            </Slider>
           
         </div>

         <div className='hp__meaning hp__meaning-impact' id='impact'>
            <h2 className='hp__section-title hp__meaning-title'>Impact <span>Social</span></h2>
            <div className='hp__impact-left'>
               <p className='hp__section-description'>Pentru a ne asigura că toți adolescenții au aceleași șanse de a participa în <b>programele EduUP</b>, oferim BURSE de studiu pentru fiecare cohortă.</p>
               <h4 className='hp__impact-title'>Eligibilitatea participării gratuite:</h4>
               <ul class="hp__impact-list">
                  <li><b>ESEU</b> de maxim 700 de cuvinte pe tema „Care este relația între aptitudinile tinerilor și capacitatea acestora de a excela în lumea afacerilor” </li>
                  <li><b>INTERVIU</b> cu echipa EduUP</li>

               </ul>
               <Link to='/' className='standard__link apply-button'>
                  <button className='hp__banner-button bursa-button'>Disponibil curand</button>
               </Link>
            </div>
            <img src="/images/impact.png" className='standard__only-desktop' />
         </div>

         <div className='hp__partners-section' id='parteneri'>
            <h2 className='hp__section-title'>Parteneri</h2>
            <p className='hp__section-description'>Misiunea noastră este să înrolăm cât mai mulți liceeni în programele noastre de educație practică, astfel, avem nevoie de parteneri pe termen lung pentru a susține. Mulțumim celor care au încredere in EduUP.</p>
            <Slider  {...partnerSettings} className='hp__slider standard__only-mobile hp__partner-slider'>
               <img src='/images/partner1.png' alt='partner' />
               <img src='/images/partner2.png' alt='partner' />
               <img src='/images/partner3.png' alt='partner' />

            </Slider>
            <div className='hp__partners standard__only-desktop'>
               <img src='/images/partner1.png' alt='partner' />
               <img src='/images/partner2.png' alt='partner' />
               <img src='/images/partner3.png' alt='partner' />

            </div>
         </div>

         <div className='hp__faq-section' id="intrebari-frecvente">
            <h2 className='hp__section-title'>Întrebări frecvente</h2>
            <div className='hp__faq-container'>
               <div className='hp__faq-left'>
                  <FAQSection faqs={faqs} />
               </div>
               <div className='hp__faq-right'>
                  <img src='/images/q1.png' />
                  <img src='/images/q2.png' />

               </div>
            </div>

         </div>

         <div className='hp__footer'>
            <div className='hp__footer-column'>
               <img src='/images/logo-white.png' alt='logo' className='hp__footer-logo' />
               <div className='hp__footer-social'>
                  <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                     <img src='/images/facebook.png' alt='facebook' />
                  </a>
                  <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                     <img src='/images/instagram.png' alt='instagram' />
                  </a>

               </div>
            </div>

            <div className='hp__footer-column'>
               <h4>EduUp</h4>
               <a href='/documents/regulament-oficial-concurs.pdf' target='_blank' rel='noopener noreferrer' className='standard__link'>
                  <h5>Regulament concurs Summer Well 2024
                  </h5>
               </a>
               <ScrollLink to='despre' className='standard__link' smooth={true} duration={500}>
                  <h5>Despre EduUp</h5>
               </ScrollLink>
               <ScrollLink to='ce-inseamna' className='standard__link' smooth={true} duration={500}>
                  <h5>Ce înseamnă EduUp</h5>
               </ScrollLink>
            </div>

            <div className='hp__footer-column'>
               <h4>SkillUp</h4>
               <ScrollLink to='skillup' className='standard__link' smooth={true} duration={500}>
                  <h5>Despre SkillUp</h5>
               </ScrollLink>
               <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                  <h5>SkillUp & Business</h5>
               </ScrollLink>
               <ScrollLink to='mentori' className='standard__link' smooth={true} duration={500}>
                  <h5>Mentori</h5>
               </ScrollLink>
            </div>

            <div className='hp__footer-column'>
               <ScrollLink to='impact' className='standard__link' smooth={true} duration={500}>
                  <h5>Impact Social</h5>
               </ScrollLink>
               <ScrollLink to='parteneri' className='standard__link' smooth={true} duration={500}>
                  <h5>Parteneri</h5>
               </ScrollLink>
               <ScrollLink to='intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                  <h5>Întrebări frecvente</h5>
               </ScrollLink>
            </div>

            <div className='hp__footer-column'>
               <h4>Contact</h4>
               <h5>asociatiaeduup@gmail.com</h5>
               <a className='standard__link' href="tel:+40722280027">
                  <h5>+40722280027</h5>
               </a>
               <a href='https://oceanobe.com/' target='_blank' className='standard__link hp__oceanobe'>
            <h5>Web platform done by the engineering experts @ OceanoBe Technology.</h5>
            </a>  
            </div>

        
         </div>
      </div>
   )
}
export default Homepage