import React, { useState } from 'react';
import '../Homepage/Homepage.scss';
import './Questionnaire.scss';
import MobileMenu from "../../components/Menu/MobileMenu";
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { formService } from "../../services/FormService";
const Questionnaire = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        grade: '',
        facultyOptions: [],
        challenges: '',
        platformPreferences: '',
        information: '',
        gdprAgreement: false,
        newsletterSubscription: false,
        otherChallenges: '',
        otherPlatformPreferences: '',
        otherFacultyOptions: ''
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox' && name === 'facultyOptions') {
            const newOptions = checked
                ? [...formData.facultyOptions, value]
                : formData.facultyOptions.filter(option => option !== value);

            setFormData((prevData) => ({
                ...prevData,
                facultyOptions: newOptions,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        formService.festivalForm(formData).then(response => {
            toast.success(`Raspunsul tau a fost trimis cu succes.`);
        })
            .catch(error => {
                console.log(error);
                toast.error('A apărut o problemă, vă rugăm să încercați din nou.');
            });
    };
    return (
        <div className='standard hp form'>
            <div className='hp__header'>
                <Link to='/' className='standard__link'>  <img src='/images/logo.png' alt='logo' className='hp__logo' /></Link>
                <MobileMenu />
                <div className='hp__desktop-menu'>
                    <Link to='/?scrollTo=despre' className='standard__link' smooth={true} duration={500}>
                        <h4>Despre </h4>
                    </Link>
                    <Link to='/?scrollTo=ce-inseamna' className='standard__link' smooth={true} duration={500}>
                        <h4>Ce înseamnă</h4>
                    </Link>
                    <Link to='/?scrollTo=skillup' className='standard__link' smooth={true} duration={500}>
                        <h4>SkillUp</h4>
                    </Link>
                    <Link to='/?scrollTo=mentori' className='standard__link' smooth={true} duration={500}>
                        <h4>Mentori</h4>
                    </Link>
                    <Link to='/?scrollTo=impact' className='standard__link' smooth={true} duration={500}>
                        <h4>Impact social</h4>
                    </Link>
                    <Link to='/?scrollTo=parteneri' className='standard__link' smooth={true} duration={500}>
                        <h4>Parteneri</h4>
                    </Link>
                    <Link to='/?scrollTo=intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                        <h4>FAQ</h4>
                    </Link>
                </div>
            </div>
            <div className='form__wrapper'>
                <form onSubmit={handleSubmit}>
                    <div className='form__inputs-box'>
                        <div className='form__box'>
                            <label className='form__label'>Nume</label>
                            <input
                                type="text"
                                name="lastName"
                                required
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='form__box'>
                            <label className='form__label'>Prenume</label>
                            <input
                                type="text"
                                name="firstName"
                                required
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                        </div>

                    </div>
                    <div className='form__inputs-box'>
                        <div className='form__box'>
                            <label className='form__label'>E-mail</label>
                            <input
                                type="email"
                                name="email"
                                required
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='form__box'>
                            <label className='form__label'>Clasă</label>
                            <input
                                type="text"
                                required
                                name="grade"
                                value={formData.grade}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='form__box form__checkbox-container'>
                        <label className='form__label'>Care sunt opțiunile pe care le iei in considerare pentru viitoarea facultate?</label>
                        <div className='form__checkbox-container'>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="Drept"
                                    checked={formData.facultyOptions.includes("Drept")}
                                    onChange={handleChange}
                                />
                                <h5>Drept</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="Medicina"
                                    checked={formData.facultyOptions.includes("Medicina")}
                                    onChange={handleChange}
                                />
                                <h5>Medicina</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="Psihologie"
                                    checked={formData.facultyOptions.includes("Psihologie")}
                                    onChange={handleChange}
                                />
                                <h5>Psihologie</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="IT"
                                    checked={formData.facultyOptions.includes("IT")}
                                    onChange={handleChange}
                                />
                                <h5>IT</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="Business"
                                    checked={formData.facultyOptions.includes("Business")}
                                    onChange={handleChange}
                                />
                                <h5>Business</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="Jurnalism"
                                    checked={formData.facultyOptions.includes("Jurnalism")}
                                    onChange={handleChange}
                                />
                                <h5>Jurnalism</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="Marketing"
                                    checked={formData.facultyOptions.includes("Marketing")}
                                    onChange={handleChange}
                                />
                                <h5>Marketing</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="Design Grafic"
                                    checked={formData.facultyOptions.includes("Design Grafic")}
                                    onChange={handleChange}
                                />
                                <h5>Design Grafic</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="Design interior"
                                    checked={formData.facultyOptions.includes("Design interior")}
                                    onChange={handleChange}
                                />
                                <h5>Design interior</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="Inginerie"
                                    checked={formData.facultyOptions.includes("Inginerie")}
                                    onChange={handleChange}
                                />
                                <h5>Inginerie</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="Arhitectură"
                                    checked={formData.facultyOptions.includes("Arhitectură")}
                                    onChange={handleChange}
                                />
                                <h5>Arhitectură</h5>
                            </div>
                            <div className='hp__checkbox form__checkbox'>
                                <input
                                    type="checkbox"
                                    name="facultyOptions"
                                    value="Alta"
                                    checked={formData.facultyOptions.includes("Alta")}
                                    onChange={handleChange}
                                />
                                <h5>Alta</h5>
                            </div>
                        </div>
                    </div>
                    {formData.facultyOptions.includes("Alta") ? <div className='form__box'>
                        <label className='form__label'>Alta optiune</label>
                        <input
                            type="text"
                            name="otherFacultyOptions"
                            value={formData.otherFacultyOptions}
                            onChange={handleChange}
                        />
                    </div> : ''}
                    <div className='form__inputs-box'>
                        <div className='form__box'>
                            <label className='form__label'>Care sunt cele mai mari provocări cu care te confrunți în ceea ce privește alegerea viitoarei cariere:</label>
                            <select
                                name="challenges"
                                className='form__select'
                                value={formData.challenges}
                                onChange={handleChange}
                            >
                                <option value="">Selectează</option>
                                <option value="Am mai multe opțiuni și nu știu pe care să o aleg">Am mai multe opțiuni și nu știu pe care să o aleg</option>
                                <option value="Încă mă gândesc dacă să aleg o facultate în România sau în străinătate">Încă mă gândesc dacă să aleg o facultate în România sau în străinătate</option>
                                <option value="Este dificil sa găsesc mentori care sa mă ghideze">Este dificil sa găsesc mentori care sa mă ghideze</option>
                                <option value="Nu știu ce domeniu ar fi cel mai potrivit pentru mine">Nu știu ce domeniu ar fi cel mai potrivit pentru mine</option>
                                <option value="Alta">Alta</option>
                            </select>
                        </div>
                        {formData.challenges.includes("Alta") ? <div className='form__box'>
                            <label className='form__label'>Altă provocare:</label>
                            <input
                                type="text"
                                name="otherChallenges"
                                value={formData.otherChallenges}
                                onChange={handleChange}
                            />
                        </div> : ''}
                    </div>
                    <div className='form__inputs-box'>

                        <div className='form__box'>
                            <label className='form__label'>Dacă ai avea la dispoziție o platformă cu resurse dedicate tinerilor, ce ai prefera să găsești acolo:</label>
                            <select
                                name="platformPreferences"
                                className='form__select'

                                value={formData.platformPreferences}
                                onChange={handleChange}
                            >
                                <option value="">Selectează</option>
                                <option value="Alți elevi de liceu cu care să pot interacționa pe domeniile mele de interes">Alți elevi de liceu cu care să pot interacționa pe domeniile mele de interes</option>
                                <option value="Practicieni (profesioniști din companii) și să pot participa la cursuri practice, organizate de aceștia">Practicieni (profesioniști din companii) și să pot participa la cursuri practice, organizate de aceștia</option>
                                <option value="Facultățile din România, grupate pe domenii, astfel încât sa am o hartă exactă a posibilităților de admitere">Facultățile din România, grupate pe domenii, astfel încât sa am o hartă exactă a posibilităților de admitere</option>
                                <option value="Profesori universitari care să mă ghideze">Profesori universitari care să mă ghideze</option>
                                <option value="Evenimente cu oportunități de networking">Evenimente cu oportunități de networking</option>
                                <option value="Altele">Altele</option>
                            </select>
                        </div>
                        {formData.platformPreferences.includes("Altele") ? <div className='form__box'>
                            <label className='form__label'>Altă preferință:</label>
                            <input
                                type="text"
                                name="otherPlatformPreferences"
                                value={formData.otherPlatformPreferences}
                                onChange={handleChange}
                            />
                        </div> : ''}
                    </div>
                    <div className='form__box'>
                        <label className='form__label'>Ce ai dori sa faci pentru a fi mai bine informat și pregătit pentru job-urile viitorului?</label>
                        <textarea
                            className='form__textarea'
                            cols='5'
                            required
                            rows='5'
                            name="information"
                            value={formData.information}
                            onChange={handleChange}
                        ></textarea>
                    </div>

                    <div className='hp__checkbox'>
                        <input
                            type="checkbox"
                            name="gdprAgreement"
                            checked={formData.gdprAgreement}
                            onChange={handleChange}
                        />
                        <h5>Sunt de acord cu normele GDPR de prelucrare a datelor personale.</h5>
                    </div>
                    <div className='hp__checkbox'>
                        <input
                            type="checkbox"
                            name="newsletterSubscription"
                            checked={formData.newsletterSubscription}
                            onChange={handleChange}
                        />
                        <h5> Sunt de acord să mă abonez la newsletterul
                            EduUP.</h5>
                    </div>
                    <button type="submit" className={`hp__form-submit form__submit ${(!formData.newsletterSubscription || !formData.gdprAgreement) ? 'disabled' : ''}`}
                        disabled={!formData.newsletterSubscription && !formData.gdprAgreement}>Vreau programa</button>                           </form>
            </div>
            <div className='hp__footer'>
                <div className='hp__footer-column'>
                    <img src='/images/logo-white.png' alt='logo' className='hp__footer-logo' />
                    <div className='hp__footer-social'>
                        <a href="https://www.facebook.com/asociatiaeduup/" target="_blank" className='standard__link'>
                            <img src='/images/facebook.png' alt='facebook' />
                        </a>
                        <a href="https://www.instagram.com/eduup.ro/" target="_blank" className='standard__link'>
                            <img src='/images/instagram.png' alt='instagram' />
                        </a>

                    </div>
                </div>

                <div className='hp__footer-column'>
                    <h4>EduUp</h4>
                    <Link to='/?scrollTo=despre' className='standard__link' smooth={true} duration={500}>
                        <h5>Despre EduUp</h5>
                    </Link>
                    <Link to='/?scrollTo=ce-inseamna' className='standard__link' smooth={true} duration={500}>
                        <h5>Ce înseamnă EduUp</h5>
                    </Link>
                </div>

                <div className='hp__footer-column'>
                    <h4>SkillUp</h4>
                    <Link to='/?scrollTo=skillup' className='standard__link' smooth={true} duration={500}>
                        <h5>Despre SkillUp</h5>
                    </Link>
                    <Link to='/?scrollTo=mentori' className='standard__link' smooth={true} duration={500}>
                        <h5>SkillUp & Business</h5>
                    </Link>
                    <Link to='/?scrollTo=mentori' className='standard__link' smooth={true} duration={500}>
                        <h5>Mentori</h5>
                    </Link>
                </div>

                <div className='hp__footer-column'>
                    <Link to='/?scrollTo=impact' className='standard__link' smooth={true} duration={500}>
                        <h5>Impact Social</h5>
                    </Link>
                    <Link to='/?scrollTo=parteneri' className='standard__link' smooth={true} duration={500}>
                        <h5>Parteneri</h5>
                    </Link>
                    <Link to='/?scrollTo=intrebari-frecvente' className='standard__link' smooth={true} duration={500}>
                        <h5>Întrebări frecvente</h5>
                    </Link>
                </div>

                <div className='hp__footer-column'>
                    <h4>Contact</h4>
                    <h5>asociatiaeduup@gmail.com</h5>
                    <a className='standard__link' href="tel:+40722280027">
                        <h5>+40722280027</h5>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Questionnaire;